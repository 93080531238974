html, body {
  height: 100%;
  margin: 0;
  padding: 0;
  position: fixed;
  width: 100%;
}

body {
  margin: 0;
  font-family: 'Helvetica Neue', helvetica,
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #fff;
  background-color: #000;
  font-size: 16px;
}

#root {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

button {
  background-color: #000;
}

button.RSVP {
  border: 2px solid #fff;
  padding: 16px 32px;
  border-radius: 80px;
  font-size: 1.2em;
  color: #fff;
  transition: 200ms linear;
}

button:hover {
  cursor: pointer;
  background-color: #fff;
  color:#000;
  transition: 200ms linear;
}

.buttonContainer {
  position: relative;
  display: flex;
  width:100%;
  height: fit-content;
  align-items: center;
  justify-content: center;
}

.buttonBorder {
  height: 108%;
  border-radius: 80px;
  border: 2px solid #fff;
}

h1 {
  font-size: 2em;
  font-weight: 500;
  text-transform: uppercase;
  text-align: center;
}

h2 {
  font-size: 1.8em;
  font-weight: 500;
  text-transform: uppercase;
  text-align: center;
}

label {
  font-size: 24px;
}

.submitButton {
  margin-top: 60px;
  width: auto;
}

@media only screen and (max-width: 768px) {
  .submitButton {
    margin-top: 16px;
    width: auto;
  }
}

img {
  transition: all 0.3s ease-out;
}

.background {
  position: absolute;
  margin-left: 0px;
  left: 0%;
  top: 0%;
  min-width: 100%;
  min-height: 100%;
  opacity: 20%;
  z-index: -1;
}

@media only screen and (max-width: 768px) {
  .background {
    width: auto;
    height: 100%;
  }
}